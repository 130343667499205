.success-page {
  display: flex;
  min-height: 100vh;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;

  @media only screen and (max-width: 600px) {
    max-width: 90%;
    margin-left: auto;
    margin-right: auto;
  }

  h1 {
    margin: 0;
    font-size: 3rem;

    @media only screen and (max-width: 600px) {
      font-size: 2rem;
      margin-bottom: 16px;
      
      span::before {
        white-space: pre;
        content: "\A";
      }
    }
  }

  a {
    color: white;
  }

  img {
    max-height: 200px;
    box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.75);
    border-radius: 32px;
    margin-bottom: 32px;
  }

  p {
    margin: 0;
    font-size: 1.5rem;
    font-weight: 500;
    margin-top: 8px;
    margin-bottom: 16px;

    @media only screen and (max-width: 600px) {
      font-size: 1rem;
      margin-bottom: 0px;
    }
  }

  span {
    max-width: 90%;

    &:not(.color) {
      @media only screen and (max-width: 600px) {
        font-size: 0.9rem;
      }
    }
  }

  span.color {
    background: -webkit-linear-gradient(30deg, #00a9a4, #00ffdc);
    text-decoration: none;

    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}
