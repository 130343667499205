.form-page {
  display: flex;
  min-height: 100vh;
  justify-content: center;
  align-items: center;

  transition: all 500ms;
  transition-timing-function: cubic-bezier(0.46, -0.535, 0.275, 1.65);

  opacity: 1;
  transform: none;

  &.hidden {
    opacity: 0;
    transform: translateX(20%) scale(0.95);
  }

  form {
    text-align: left;
    padding: 32px;

    display: flex;
    flex-direction: column;
    row-gap: 12px;

    .error {
      color: rgb(216, 45, 45);
      font-weight: 500;
      margin-top: 4px;
      padding: 8px;
      border-radius: 8px;
      background-color: rgb(213, 31, 31, 0.15);
    }

    h3 {
      font-size: 2.5rem;
      font-weight: 800;
      margin: 0;

      @media only screen and (max-width: 500px) {
        font-size: 2.0rem;
        margin-bottom: 8px;
      }

      span {
        background: -webkit-linear-gradient(30deg, #00a9a4, #00ffdc);
        text-decoration: none;

        background-clip: text;
        -webkit-background-clip: text;
        -moz-background-clip: text;
        -webkit-text-fill-color: transparent;
        -moz-text-fill-color: transparent;
      }
    }

    p.subtitle {
      margin: 0;
      margin-top: -12px;
      font-size: 1.2rem;
      font-weight: 500;
    }

    input[type="text"],
    input[type="email"],
    input[type="password"] {
      padding: 20px;
      border-radius: 12px;
      border: none;
      font-size: 1rem;
      font-weight: 400;
    }

    label {
      font-weight: 500;
      margin-top: 12px;
    }

    p:not(.subtitle) {
      margin: 0;
      margin-bottom: 32px;
      font-weight: 450;
    }

    button[type="submit"],
    input[type="submit"],
    input[type="reset"] {
      text-align: start;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
      
      font-weight: 400;
      font-size: 0.75rem;
      background: -webkit-linear-gradient(30deg, #00a9a4, #00ffdc);
      text-shadow: 2px 2px 20px rgba(0, 0, 0, 0.55);
      text-align: center;
      border-radius: 12px;
      width: 50%;

      margin-left: auto;
      margin-right: auto;

      transition: transform 0.2s;

      &:hover:not(.disabled) {
        transform: scale(1.05)
      }

      &.disabled {
        opacity: 0.5;
        cursor: not-allowed;
      }

      @media only screen and (max-width: 800px) {
        font-size: 0.60rem;
        width: 80%;
      }
    }
  }
}
