.app {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;

  @media only screen and (max-width: 800px) {
    flex-direction: column;
    margin: 0 16px;
    margin-bottom: 32px;
  }

  column-gap: 32px;

  transition: all 500ms;
  transition-timing-function: cubic-bezier(0.46, -0.535, 0.275, 1.65);

  opacity: 1;
  transform: none;

  &.hidden {
    opacity: 0;
    transform: translateX(20%) scale(0.95);
  }

  &.hidden-left {
    opacity: 0;
    transform: translateX(-20%) scale(0.95);
  }

  h2 {
    font-size: 3rem;
    margin: 0;
    margin-bottom: 8px;
  }

  p {
    margin: 0;
    font-weight: 500;
    font-size: 1.5rem;
    max-width: 450px;
  }

  .phone {
    max-width: 400px;

    @media only screen and (max-width: 800px) {
      max-width: 350px;
    }
  }

  button.clear {
    text-align: start;
    background: none;
    color: inherit;
    border: none;
    padding: 0;
    font: inherit;
    cursor: pointer;
    outline: inherit;
  }

  button.action {
    margin-top: 16px;
    font-size: 1.5rem;
    font-weight: 600;

    background: -webkit-linear-gradient(30deg, #00a9a4, #00ffdc);

    background-clip: text;
    -webkit-background-clip: text;
    -moz-background-clip: text;
    -webkit-text-fill-color: transparent;
    -moz-text-fill-color: transparent;
  }
}
