.landing-page {
  display: flex;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  overflow: hidden;

  @media only screen and (max-width: 600px) {
    min-height: 80vh;
  }

  &.hidden {
    transition: all 0.3s;
    opacity: 0;
    transform: translateX(-20%) scale(0.95);
  }

  a {
    color: white;
    display: inline;
    display: flex;
    text-decoration: none;
  }

  form {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-grow: 1;

    margin-top: 72px;

    column-gap: 42px;

    @media only screen and (max-width: 800px) {
      flex-direction: column;
    }

    button,
    input[type="submit"],
    input[type="reset"] {
      text-align: start;
      background: none;
      color: inherit;
      border: none;
      padding: 0;
      font: inherit;
      cursor: pointer;
      outline: inherit;
    }

    img {
      max-height: 200px;
      max-width: 200px;

      box-shadow: 10px 10px 30px rgba(0, 0, 0, 0.75);
      border-radius: 32px;
      object-fit: cover;
    }

    button {
      h1 {
        font-size: 4rem;
        font-weight: 750;
        margin: 0;

        @media only screen and (max-width: 600px) {
          margin-top: 16px;
          font-size: 2.5rem;
        }

        @media only screen and (max-width: 400px) {
          font-size: 1.5rem;
        }

        transition: all 0.2s ease-in-out;

        &:hover {
          transform: scale(1.025);
          font-weight: 800;

          span {
            animation: button-animation 0.1s 0.1s linear forwards;
            text-shadow: 2px 2px 20px #02bcb62b;
          }
        }

        span {
          border: none;
          background-size: -30%;
          background: -webkit-linear-gradient(30deg, #00a9a4, #00ffdc);
          text-decoration: none;

          background-clip: text;
          -webkit-background-clip: text;
          -moz-background-clip: text;
          -webkit-text-fill-color: white;
          -moz-text-fill-color: white;
        }
      }
    }
  }
}

@keyframes button-animation {
  0% {
    background-position: top right;
    -webkit-text-fill-color: rgba(255, 255, 255, 1);
  }
  10% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.9);
  }
  20% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.8);
  }
  30% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.7);
  }
  40% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.6);
  }
  50% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.5);
  }
  60% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.4);
  }
  70% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.3);
  }
  80% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.2);
  }
  90% {
    -webkit-text-fill-color: rgba(255, 255, 255, 0.1);
  }
  100% {
    -webkit-text-fill-color: transparent;
  }
}
